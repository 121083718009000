// App.js
import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import ApiClient from '../auth/AuthService';
import ProspectLeadsDashboard from '../ProspectLeads/ProspectLeadsDashboard';
import Login from '../Login/Login';
import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import LeadsDashboard from '../LeadsDashboard/LeadsDashboard';
import Report from '../Reports/Report';
import NewClients from "../NewClients/NewClients";
import UploadFiles from "../UploadFiles/UploadFiles";
import DjangoAdmin from "../DjangoAdmin/DjangoAdmin";
import Navbar from "../Navbar/Navbar";

// Добавьте эту строку в начало файла
import { APP_VERSION } from '../config';

const apiClient = ApiClient();

// Компонент AccessControl
const AccessControl = ({ accessLevel, allowedLevels, children }) => {
    console.log("AccessControl - Current level:", accessLevel, "Allowed levels:", allowedLevels);
    if (allowedLevels.includes(accessLevel)) {
        return children;
    }
    return null;
};

function App() {
    const [currentUser, setCurrentUser] = useState(null);
    const [sessionExists, setSessionExists] = useState(false);
    const [accessLevel, setAccessLevel] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const handleLogout = useCallback(async () => {
        try {
            await apiClient.logout();
            sessionStorage.removeItem(`user_${APP_VERSION}`);
            setCurrentUser(null);
            setAccessLevel(null);
            sessionStorage.removeItem(`session-id_${APP_VERSION}`);
            setSessionExists(false)
        } catch (error) {
            console.error("Logout failed:", error);
        }
    }, []);

    const fetchUser = useCallback(async () => {
            try {
                const user = await apiClient.get('auth/user/');
                console.log('!!! User = ', user);
                setCurrentUser(user);
                setAccessLevel(user.access_level);
                sessionStorage.setItem(`user_${APP_VERSION}`, JSON.stringify(user));
            } catch (error) {
                console.error("Error fetching user data:", error);
                handleLogout();
            }
            setIsLoading(false);
        }, [handleLogout]);



    const handleLogin = useCallback(async (username, password) => {
          try {
            const user = await apiClient.login(username, password);
            //console.log('!!!!!!!user = ', user);
            setCurrentUser(user);
            setAccessLevel(user.access_level);
            sessionStorage.setItem(`user_${APP_VERSION}`, JSON.stringify(user));
            sessionStorage.setItem(`session-id_${APP_VERSION}`, Date.now().toString());
            //setSessionExists(true);
            console.log('User data saved to sessionStorage:', sessionStorage.getItem(`user_${APP_VERSION}`));
          } catch (error) {
            console.error("Login failed:", error);
            throw error;
          }
        }, []);


     useEffect(() => {
        const checkAuth = async () => {
            const storedUser = sessionStorage.getItem(`user_${APP_VERSION}`);
            const sessionId = sessionStorage.getItem(`session-id_${APP_VERSION}`);
            console.log('!!!! storedUser', storedUser)
            // Проверка и установка session-id
            if (sessionId) {
                setSessionExists(true);
            } else {
                sessionStorage.setItem(`session-id_${APP_VERSION}`, Date.now().toString());
            }

            if (storedUser) {
                const user = JSON.parse(storedUser);
                setCurrentUser(user);
                setAccessLevel(user.access_level);
                setIsLoading(false); // Останавливаем загрузку, если пользователь найден в sessionStorage
            } else if (await apiClient.isAuth()) {
                // Если в sessionStorage нет данных, проверяем авторизацию и загружаем данные пользователя
                await fetchUser();
            } else {
                setIsLoading(false);
            }
        };

        checkAuth();

        // Удаляем идентификатор сессии при закрытии вкладки
         const handleBeforeUnload = () => {
            sessionStorage.removeItem(`session-id_${APP_VERSION}`);
            //sessionStorage.removeItem(`user_${APP_VERSION}`);
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [fetchUser]);

    useEffect(() => {
        document.title = "LeadsGen";
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (sessionExists && currentUser) {
        return (
            <div>
                <h1>This application is already running in this browser.</h1>
                <p>Please close any other tabs that have this application open.</p>
            </div>
        );
    }

    return (
        <Router>
            {currentUser &&(
                <Navbar
                    username={currentUser.username}
                    onLogout={handleLogout}
                    accessLevel={accessLevel}
                />
            )}
            <Routes>
                <Route path="/login" element={<Login onLogin={handleLogin} />} />
                <Route
                    path="/"
                    element={
                        <ProtectedRoute>
                            <LeadsDashboard user={currentUser} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/leads"
                    element={
                        <ProtectedRoute>
                            <LeadsDashboard user={currentUser} />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/reports"
                    element={
                        <ProtectedRoute>
                            <AccessControl accessLevel={accessLevel} allowedLevels={['admin', 'boss']}>
                                <Report user={currentUser} />
                            </AccessControl>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/prospect"
                    element={
                        <ProtectedRoute>
                            <AccessControl accessLevel={accessLevel} allowedLevels={['admin']}>
                                <ProspectLeadsDashboard user={currentUser} />
                            </AccessControl>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/newclients"
                    element={
                        <ProtectedRoute>
                            <AccessControl accessLevel={accessLevel} allowedLevels={['admin']}>
                                <NewClients user={currentUser} />
                            </AccessControl>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/uploadclients"
                    element={
                        <ProtectedRoute>
                            <AccessControl accessLevel={accessLevel} allowedLevels={['admin', 'boss']}>
                                <UploadFiles user={currentUser} />
                            </AccessControl>
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="/admin"
                    element={
                        <ProtectedRoute>
                            <AccessControl accessLevel={accessLevel} allowedLevels={['admin']}>
                                <DjangoAdmin />
                            </AccessControl>
                        </ProtectedRoute>
                    }
                />
                <Route path="*" element={<Navigate to="/" />} />
            </Routes>
        </Router>
    );
}

export default App;