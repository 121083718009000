// Navbar.jsx
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Button,
  Box,
} from '@mui/material';
import { AccountCircle } from '@mui/icons-material';
import logo from '../img/logo.png';

const Navbar = ({ username, onLogout, accessLevel }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  const menuItems = [
    { label: 'Leads', path: '/leads', allowedLevels: ['admin', 'boss'] },
    { label: 'Reports', path: '/reports', allowedLevels: ['admin', 'boss'] },
    { label: 'Prospect-Leads', path: '/prospect', allowedLevels: ['admin'] },
    { label: 'New Clients', path: '/newclients', allowedLevels: ['admin'] },
    { label: 'Upload Clients', path: '/uploadclients', allowedLevels: ['admin', 'boss'] },
  ];

  return (
    <AppBar position="static">
      <Toolbar sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{display: 'flex', alignItems: 'flex-start'}}>
          <img src={logo} alt="Logo" style={{height: '40px', marginRight: '10px'}}/>
          <Typography variant="h5" component="div">
            LeadsGen
          </Typography>
          <Box sx={{display: 'flex', flexDirection: 'column', marginLeft: 7}}>
            <Box sx={{height: '10px'}}/> {/* Пустое пространство для сдвига кнопок вниз */}
            <Box sx={{display: 'flex'}}>
              {menuItems.map((item, index) => (
                item.allowedLevels.includes(accessLevel) && (
                  <Button
                    key={index}
                    color="inherit"
                    component={Link}
                    to={item.path}
                    size="small"
                  >
                    {item.label}
                  </Button>
                )
              ))}
            </Box>
          </Box>
        </Box>
        <Box sx={{flexGrow: 1}}/>
        <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
          {username}
        </Typography>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleMenu}
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;